import React from "react";
import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import '../css/main.css'
import '../css/slider.css'

import image1 from '../assets/photo/slider/image_1.jpg'
import image2 from '../assets/photo/slider/image_66.jpg'
import image3 from '../assets/photo/slider/image_33.jpg'
import image4 from '../assets/photo/slider/image_4.jpg'
import image5 from '../assets/photo/slider/image_5.jpg'
import image6 from '../assets/photo/slider/image_6.jpg'


function Slider() {
  return(
    <div className="container" id="main"> 
      <h1 className="heading">TRAP HOUSE</h1>
      <Swiper
      effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
      <SwiperSlide><img src={image2} alt="Slider_Image"></img></SwiperSlide>
      <SwiperSlide><img src={image1} alt="Slider_Image"></img></SwiperSlide>
      <SwiperSlide><img src={image3} alt="Slider_Image"></img></SwiperSlide>
      <SwiperSlide><img src={image4} alt="Slider_Image"></img></SwiperSlide>
      <SwiperSlide><img src={image5} alt="Slider_Image"></img></SwiperSlide>
      <SwiperSlide><img src={image6} alt="Slider_Image"></img></SwiperSlide>
      
      <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
    </Swiper>
    <Link to="/gallery" className="sliderText">[ Смотреть больше фото ]</Link>
    </div>
  )
};

export default Slider;