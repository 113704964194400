import React from "react";
import '../css/header.css'
import '../css/main.css'
import { Link } from 'react-router-dom';


function menuToggle() {
  const navContainer = document.querySelector(".nav-container")
  navContainer.classList.toggle("active")
}
function auth() {
  alert("Данная функция на данный момент находится в активной разработке. Следите за новостями!")
}
 
function Header() {
  return ( 
    <div className="nav-container" onClick={menuToggle}>
      <nav>
        <ul className="mobile-nav">
          <li>
            <div className="menu-icon-container">
              <div className="menu-icon">
                <span className="line-1"></span>
                <span className="line-2"></span>
              </div>
            </div>
          </li>

          <li><Link to="/#main" className="headerLogo"> </Link></li>
          <li><Link to="/#" className="headerPerson" onClick={auth}> </Link></li>
        </ul>

        <ul className="desktop-nav">
          <li><Link to="/#main" className="headerLogo"> </Link></li>
          <li><Link to="/#main">Главная</Link></li>
          <li><a href="#ticket">Билеты</a></li>
          <li><a href="#chat">Чат</a></li>
          <li><a href="#faq">FAQ</a></li>
          <li><a href="#about">О нас</a></li>
          <li><Link to="/gallery">Галерея</Link></li>
          <li><Link to="#" className="headerPerson" onClick={auth}> </Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;