import './css/main.css'
import React from 'react';
import { Link } from 'react-router-dom';

import './css/gallery.css';

function Button(props) {
  return(
    <div>
      <Link target={"_self"} rel="noreferrer" to={props.link} className="galleryBtn">{props.text}</Link>
    </div>
    );
}

function Gallery() {
  return (
      <div>
        <div className="containerGallery" id="ticket">
          <h1 className="galleryMainText">Нажав на кнопку</h1>
          <h1 className="galleryChoise">Выбери что посмотреть</h1>
          <div className="buttonsContainer">
            <Button link="/gallery/photo" text="Фото"></Button>
            <Button link="/gallery/video" text="Видео"></Button>
          </div>
        </div>
      </div>
  );
}
 
export default Gallery;
