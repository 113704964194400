import React from 'react'

import '../css/gallery.css'

import photo1 from '../assets/photo/gallery/1.jpg'
import photo2 from '../assets/photo/gallery/2.jpg'
import photo3 from '../assets/photo/gallery/3.jpg'
import photo4 from '../assets/photo/gallery/4.jpg'
import photo5 from '../assets/photo/gallery/5.jpg'
import photo6 from '../assets/photo/gallery/6.jpg'
import photo7 from '../assets/photo/gallery/7.jpg'
import photo8 from '../assets/photo/gallery/8.jpg'
import photo9 from '../assets/photo/gallery/9.jpg'
import photo10 from '../assets/photo/gallery/10.jpg'
import photo11 from '../assets/photo/gallery/11.jpg'
import photo12 from '../assets/photo/gallery/12.jpg'
import photo13 from '../assets/photo/gallery/13.jpg'
import photo14 from '../assets/photo/gallery/14.jpg'
import photo15 from '../assets/photo/gallery/15.jpg'
import photo16 from '../assets/photo/gallery/16.jpg'
import photo17 from '../assets/photo/gallery/17.jpg'
import photo18 from '../assets/photo/gallery/18.jpg'
import photo19 from '../assets/photo/gallery/19.jpg'
import photo20 from '../assets/photo/gallery/20.jpg'
import photo21 from '../assets/photo/gallery/21.jpg'
import photo22 from '../assets/photo/gallery/22.jpg'
import photo23 from '../assets/photo/gallery/23.jpg'
import photo24 from '../assets/photo/gallery/24.jpg'
import photo25 from '../assets/photo/gallery/25.jpg'
import photo26 from '../assets/photo/gallery/26.jpg'
import photo27 from '../assets/photo/gallery/27.jpg'
import photo28 from '../assets/photo/gallery/28.jpg'
import photo29 from '../assets/photo/gallery/29.jpg'
import photo30 from '../assets/photo/gallery/30.jpg'
import photo31 from '../assets/photo/gallery/31.jpg'
import photo32 from '../assets/photo/gallery/32.jpg'
import photo33 from '../assets/photo/gallery/33.jpg'

function Photo() {
  return(
    <main className='wrapper'>
      <h1 className='galleryText'>Галерея фотографий с наших мероприятий.</h1>
      <div className='img-box'>
        <img src={photo1} alt="Something wrong :("/>
        <img src={photo2} alt="Something wrong :("/>
        <img src={photo3} alt="Something wrong :("/>
        <img src={photo4} alt="Something wrong :("/>
        <img src={photo5} alt="Something wrong :("/>
        <img src={photo6} alt="Something wrong :("/>
        <img src={photo7} alt="Something wrong :("/>
        <img src={photo8} alt="Something wrong :("/>
        <img src={photo9} alt="Something wrong :("/>
        <img src={photo10} alt="Something wrong :("/>
        <img src={photo11} alt="Something wrong :("/>
        <img src={photo12} alt="Something wrong :("/>
        <img src={photo13} alt="Something wrong :("/>
        <img src={photo14} alt="Something wrong :("/>
        <img src={photo15} alt="Something wrong :("/>
        <img src={photo16} alt="Something wrong :("/>
        <img src={photo17} alt="Something wrong :("/>
        <img src={photo18} alt="Something wrong :("/>
        <img src={photo19} alt="Something wrong :("/>
        <img src={photo20} alt="Something wrong :("/>
        <img src={photo21} alt="Something wrong :("/>
        <img src={photo22} alt="Something wrong :("/>
        <img src={photo23} alt="Something wrong :("/>
        <img src={photo24} alt="Something wrong :("/>
        <img src={photo25} alt="Something wrong :("/>
        <img src={photo26} alt="Something wrong :("/>
        <img src={photo27} alt="Something wrong :("/>
        <img src={photo28} alt="Something wrong :("/>
        <img src={photo29} alt="Something wrong :("/>
        <img src={photo30} alt="Something wrong :("/>
        <img src={photo31} alt="Something wrong :("/>
        <img src={photo32} alt="Something wrong :("/>
        <img src={photo33} alt="Something wrong :("/>
      </div>
    </main>
  );
}

export default Photo;