import React from 'react'

import '../css/faq.css'

var symbols = "><"

function Faq() {
  return(
    <div className='faqContainer' id="faq">
      <div className='faqWrap'>
      <h1>FAQ</h1>
        <div className='firstBlock'>
          <h2>Что делать если не пришел онлайн-билет?</h2>
          <p>{symbols[0]} Проверить папку 'Спам' в почтовом ящике.</p>
          <p>{symbols[0]} Сообщить о проблеме в личные сообщения группы <a href="https://vk.com/traphouserussia">[ VK ]</a>, указав номер заказа.</p>
        </div>
        <div className='secondBlock'>
          <h2>Возврат онлайн-билетов</h2>
          <p>{symbols[0]} 100% Возврат средств делается минимум за 3 дня до мероприятия.Билеты приобретённые по скидке с прокодом,возврату не подлежат.Для оформления заявки на возврат билета необходимо <a href='https://qtickets.ru/refund
'>[ перейти сюда ]</a>.</p>
          <p>{symbols[0]} Сообщить о намерениях в личные сообщения группы <a href="https://vk.com/traphouserussia">[ VK ]</a>.</p>
        </div>
        <div className='thirdBlock'>
          <h2>Где узнать актуальную информацию о предстоящих мероприятиях?</h2>
          <p>{symbols[0]} Всю актуальную инормацию мы всегда выкладываем в наш <a href='https://t.me/traphouserussia
'>[ Telegram-канал ]</a>.</p>
          <h2>Покупая билет вы подтверждаете что вам уже исполнилось 16 лет.</h2>
        </div>
      </div>
    </div>
  );
}

export default Faq;