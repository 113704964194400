import React from 'react'

import '../css/artists.css'

import august from '../assets/photo/artist/August.png'
import heron from '../assets/photo/artist/Heronwater.png'
import magnum from '../assets/photo/artist/Magnum-Opus.png'
import nkeeei from '../assets/photo/artist/NKEEEI.UNIGE.png'
import og from '../assets/photo/artist/OG Buda.png'
import pinq from '../assets/photo/artist/PinQ.png'
import stephan from '../assets/photo/artist/Uglystephan.png'
import yanix from '../assets/photo/artist/Yanix.png'
import platina from '../assets/photo/artist/platina.png'
import lizer from '../assets/photo/artist/lizer.png'
import xxxmanera from '../assets/photo/artist/manera.png'
import lilgrughill from '../assets/photo/artist/lilg.png'
import toxis from '../assets/photo/artist/toxis.png'
import scally from '../assets/photo/artist/scaly.png'
import pepel from '../assets/photo/artist/pepel.png'
import hofma from '../assets/photo/artist/hofma.png'

import next from '../assets/photo/artist/Secret.png'
function ArtistItem(props) {
  return(
    <div className='artistItem'>
      <div className='artistItemImg'>
        <img src={props.source} alt="Failed to get :("></img>
      </div>
      <h2>{props.text}</h2>
    </div>
  );
}

function Artists() {
  return(
    <div className='artistContainer'>
    <h1>Провели концерты</h1>
      <div className='artistWrap'>
        <ArtistItem source={yanix} text="yanix"></ArtistItem>
        <ArtistItem source={stephan} text="uglystephan"></ArtistItem>
        <ArtistItem source={og} text="og buda"></ArtistItem>
        <ArtistItem source={heron} text="heronwater"></ArtistItem>
        <ArtistItem source={magnum} text="magnum opus"></ArtistItem>
        <ArtistItem source={pinq} text="pinq"></ArtistItem>
        <ArtistItem source={nkeeei} text="nkeeei.uniqe.artem shilovets"></ArtistItem>
        <ArtistItem source={august} text="august"></ArtistItem>
        <ArtistItem source={platina} text="платина"></ArtistItem>
        <ArtistItem source={lizer} text="lizer"></ArtistItem>
        <ArtistItem source={xxxmanera} text="xxxmanera"></ArtistItem>
        <ArtistItem source={lilgrughill} text="lildrughill"></ArtistItem>
        <ArtistItem source={toxis} text="toxis"></ArtistItem>
        <ArtistItem source={scally} text="scally milano"></ArtistItem>
        <ArtistItem source={pepel} text="pepel nahudi"></ArtistItem>
        <ArtistItem source={hofma} text="hofmannita"></ArtistItem>
      </div>
      <ArtistItem source={next} text="Кто следующий?"></ArtistItem>
    </div>
  );
}

export default Artists;