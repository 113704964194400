import React from 'react';
import ReactPlayer from 'react-player';

import '../css/gallery.css';

import video1 from '../assets/video/IMG_4739.MOV'
import video2 from '../assets/video/IMG_4771.MOV'
import video3 from '../assets/video/IMG_4836.mp4'

function Video() {
  return(
    <main className='wrapper'>
      <h1 className='galleryText'>Галерея видео с наших мероприятий.</h1> 
      <div className='video-box'>
        <ReactPlayer width='100%' height='auto' className='video' url={video1} controls/>
        <ReactPlayer width='100%' height='auto' className='video' url={video2} controls/>
        <ReactPlayer width='100%' height='auto' className='video' url={video3} controls/>
      </div>
    </main>  
  );
}

export default Video;