import React from 'react'

import '../css/team.css'

import teamPhoto1 from "../assets/photo/team/IMG_4666.JPG"
import teamPhoto2 from "../assets/photo/team/IMG_3795.JPG"
import teamPhoto3 from "../assets/photo/team/photo_2023-03-01_21-13-53.jpg"

function TeamItem(props) {
  return(
    <div className='teamItem'>
      <div className='teamItemImg'>
        <img src={props.source} alt="Failed to get :("></img>
      </div>
      <h2>{props.text}</h2>
      <h3>{props.rank}</h3>
    </div>
  );
}

function Team() {
  return(
    <div className='teamContainer'>
    <h1>Наша команда</h1>
      <div className='teamWrap'>
        <TeamItem source={teamPhoto1} rank="Владелец. Дизайнер." text="Александр"></TeamItem>
        <TeamItem source={teamPhoto2} rank="Организатор. Диджей." text="William Hill"></TeamItem>
        <TeamItem source={teamPhoto3} rank="Организатор. Диджей." text="Veelone"></TeamItem>
      </div>
    </div>
  );
}

export default Team;