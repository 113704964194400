import React from 'react'

import '../css/social.css'

import tgIcon from "../assets/telegram_icon.png"
import vkIcon from "../assets/vk_icon.png"

function Item(props) {
  return(
    <div className="social">
      <h2 className='socialFirst'><img className='socialImage' src={props.src} alt={props.alt}></img> {props.name}</h2>
      <p><a target={"_blank"} rel="noreferrer" href={props.link}>{props.text}</a></p>
    </div>
  );
}

function Social() {
  return(
    <div className="socialContainer">
      <div className='socialWrap'>
         <h1>Наши социальные сети</h1>
         <Item src={tgIcon} alt="No image :(" name="Telegram" link="https://t.me/traphouserussia" text="> Телеграмм-канал <"></Item>
         <Item src={vkIcon} alt="No image :(" name="VK" link="https://vk.com/traphouserussia" text="> Группа ВКонтакте <"></Item>
      </div>
    </div>
  );
}

export default Social;