import React from "react";

import '../css/tickets.css'

var MAYOT = 'https://qtickets.ru/iframe?event_id=128978&lang=ru&site_url=https%3A%2F%2Fsochi.qtickets.events%2F128978-mayot-27-sentyabrya&host=sochi.qtickets.events&winclose=1'
var BBT = 'https://sochi.qtickets.events/126060-big-baby-tape?utm_source=trap&utm_medium=house&utm_campaign=trap'
var FEST = 'https://sochi.qtickets.events/129411-south-festival-4-artista-predprodazha'

function Button(props) {
  return(
    <a target={"_blank"} rel="noreferrer" href={props.link} className="ticketBtn">{props.text}</a>
  );
}

function Tickets() {
  return(
    <div className="containerTickets" id="ticket">
      <h1 className="ticketsToBuy">Чтобы купить билет</h1>
      <h1 className="ticketsChoise">Выбери мероприятие</h1>
      <div className="ticketsContainer">
        <Button link={MAYOT} text="MAYOT"></Button>
        <Button link={BBT} text="BIG BABY TAPE"></Button>
        <Button link={FEST} text="FESTIVAL"></Button>
      </div>
      <p>*Если кнопка не работает, то билеты приобретаются на входе</p>
    </div>
  )
}

export default Tickets;