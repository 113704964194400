import React from 'react'

import '../css/about.css'

function  About() {
  return(
    <div className='aboutContainer' id='about'>
      <div className='aboutWrap'>
        <h1>О нас</h1>
        <p><span>Trap House</span> - команда, занимающаяся организацией концертов и вечеринок по всей России!</p>
        <p>Мы провели более 500 мероприятий за 4 года. У нас работают лучшие диджеи с большим опытом выступлений как на вечеринках, так и на больших концертах.</p>
        <p>До встречи на наших ивентах!</p>
      </div>
    </div>
  );
}

export default About;